export enum ActionType
 {
  INIT_ENSEMBLE        = 'INIT_ENSEMBLE'        ,
  ADD_REGISTRATION     = 'ADD_REGISTRATION'     ,
  ADD_PHOTO            = 'ADD_PHOTO'            ,
  UPDATE_PHOTO_REVIEW  = 'UPDATE_PHOTO_REVIEW'  ,            
  INIT_CLASSEMENT      = 'INIT_CLASSEMENT'      ,
  ADD_CLASSEMENT_PHOTO = 'ADD_CLASSEMENT_PHOTO'
 }

export interface Photo
 {
  id:                  number  ,
  file:                string  ,
  name:                string  ,
  description:         string  ,
  cameraBrand:         string  ,
  cameraModel:         string  ,
  choosen:             boolean ,
  authorCertification: boolean ,
  newState:            number  ,
  review:              string  ,
  dateCreation:        string  ,
  dateReview:          string  ,
  nbVotes:             number
 }
 
export interface Registration
 {
  id:           number  ,
  email:        string  ,
  password:     string  ,
  password2:    string  ,
  firstname:    string  ,
  lastname:     string  ,
  sex:          string  ,
  birthday:     string  ,
  address:      string  ,
  address2:     string  ,
  zipCode:      string  ,
  city:         string  ,
  country:      string  ,
  phoneNumber:  string  ,
  website:      string  ,
  instagram:    string  ,
  facebook:     string  ,
  tiktok:       string  ,
  bio:          string  ,
  verify:       boolean ,
  dateCreation: string  
  photos:       Photo[]
 }

export interface Ensemble
 {
  registrations: Registration[],
  typeInfos:     string
 }

export interface PhotoClassee
 {
  id:           number  ,
  file:         string  ,
  name:         string  ,
  description:  string  ,
  urlThumbnail: string  ,
  urlMagnified: string  ,
  firstname:    string  ,
  lastname:     string  ,
  nbVotes:      number
 }

export interface Classement
 {
  photos: PhotoClassee[]
 }

export interface actionInitEnsemble
 {
  type:                   ActionType.INIT_ENSEMBLE;
  newTypeInfos:           string;
 }

export interface actionAddRegistration
 {
  type:                   ActionType.ADD_REGISTRATION;
  newRegistration:        Registration;  
 }

export interface actionAddPhoto
 {
  type:                   ActionType.ADD_PHOTO;
  numRegistration:        number;
  newPhoto:               Photo;  
 }

export interface actionUpdatePhotoReview
 {
  type:                   ActionType.UPDATE_PHOTO_REVIEW;
  numRegistration:        number;
  numPhoto:               number;
  newReview:              string;
 }

export interface actionInitClassement
 {
  type:                   ActionType.INIT_CLASSEMENT;
 }

export interface actionAddClassementPhoto
 {
  type:                   ActionType.ADD_CLASSEMENT_PHOTO;
  newPhoto:               PhotoClassee;  
 }


export type Action = actionInitEnsemble       | 
                     actionAddRegistration    |
					 actionAddPhoto           |
					 actionUpdatePhotoReview  |
					 actionInitClassement     | 
                     actionAddClassementPhoto ;


export type DispatchTypeInitEnsemble       = ( args: actionInitEnsemble       ) => actionInitEnsemble 
export type DispatchTypeAddRegistration    = ( args: actionAddRegistration    ) => actionAddRegistration
export type DispatchTypeAddPhoto           = ( args: actionAddPhoto           ) => actionAddPhoto
export type DispatchTypeUpdatePhotoReview  = ( args: actionUpdatePhotoReview  ) => actionUpdatePhotoReview
export type DispatchTypeInitClassement     = ( args: actionInitClassement     ) => actionInitClassement
export type DispatchTypeAddClassementPhoto = ( args: actionAddClassementPhoto ) => actionAddClassementPhoto

// urls.ts

const WWW_BASE_URL = 'https://jury.lovelightchallenge.com';
const API_BASE_URL = 'https://api.lovelightchallenge.com';


export const API_URLS = {
  //GetInfos:                `${API_BASE_URL}/api/users`,
  GetInfos2:               `${API_BASE_URL}/api/usersbytype`,

  GetClassementPhotos:     `${API_BASE_URL}/api/comptage`, 

  //GetInfosUserByEmail:     `${API_BASE_URL}/api/by_email`,
  //GetInfosUserById:        `${API_BASE_URL}/api/users/`,

  PostConnection:          `${API_BASE_URL}/api/connections`,

  GetPhotosByEmail:        `${API_BASE_URL}/api/photosbyemail`,
  //GetPhotosById:           `${API_BASE_URL}/api/photos/`,
  GetPhotosForVote:        `${API_BASE_URL}/api/photosforvote`,
  
  PatchPhoto:              `${API_BASE_URL}/api/photos/`,

  PostAuth:                `${API_BASE_URL}/auth`,
  
  Test:                    'https://webhook.site/d3023ee7-42da-4087-8464-f1c34fb0bb76'
};

export const WWW_URLS = {
  Home:                 `${WWW_BASE_URL}`
};

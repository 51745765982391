import   React        , 
       { useState     }  from "react";
import   classNames      from 'classnames';

import   styles          from './album.module.scss';
import { Registration }  from '../../redux/registrationManagment/actionTypes';
import { Photo        }  from '../bloc_photo/photo';

export interface AlbumProps {
  className?:     string;
  user:       Registration;
}

export const Album = ({ className ,
                        user      }: AlbumProps) => {


 if ( user.photos.length > 0 )
        {
         return (

                   <div className={classNames(styles.bloc, className)}>

                     { user.photos.map ( ( photo, index ) => ( <Photo index={index} user={user} /> ) ) }

                   </div>
	            )
        }
   else {
         return <></>
        }
};

export default Album;

import { Dispatch } from 'redux';

import { ActionType                     ,
         Ensemble                       ,
         actionInitEnsemble             ,
		 actionAddRegistration          ,
         actionAddPhoto                 ,
		 actionUpdatePhotoReview        ,
		 actionAddClassementPhoto       ,
		 actionInitClassement           ,
		 DispatchTypeInitEnsemble       ,
		 DispatchTypeAddRegistration    ,
		 DispatchTypeAddPhoto           ,
		 DispatchTypeUpdatePhotoReview  ,
		 DispatchTypeInitClassement     , 
		 DispatchTypeAddClassementPhoto } from './actionTypes';

export const initEnsemble = (newTypeInfos: string) => 
 {
  const action: actionInitEnsemble = 
   {
    type:          ActionType.INIT_ENSEMBLE,
	newTypeInfos:  newTypeInfos
   }

  return ( dispatch: DispatchTypeInitEnsemble ) => 
   {
    dispatch (action);
   }
 } 

export const addRegistration = ( newId:           number  ,
                                 newEmail:        string  ,
                                 newPassword:     string  ,
                                 newPassword2:    string  ,
                                 newFirstname:    string  ,
                                 newLastname:     string  ,
                                 newSex:          string  ,
                                 newBirthday:     string  ,
                                 newAddress:      string  ,
                                 newAddress2:     string  ,
                                 newZipCode:      string  ,
                                 newCity:         string  ,
                                 newCountry:      string  ,
                                 newPhoneNumber:  string  ,
                                 newWebsite:      string  ,
                                 newInstagram:    string  ,
                                 newFacebook:     string  ,
                                 newTiktok:       string  ,
                                 newBio:          string  ,
                                 newVerify:       boolean ,
								 newDateCreation: string  ) =>
 {
  const action: actionAddRegistration =
   {
    type:           ActionType.ADD_REGISTRATION,
	newRegistration: {
      id:           newId           ,
      email:        newEmail        ,
      password:     newPassword     ,
      password2:    newPassword2    ,
      firstname:    newFirstname    ,
      lastname:     newLastname     ,
      sex:          newSex          ,
      birthday:     newBirthday     ,
      address:      newAddress      ,
      address2:     newAddress2     ,
      zipCode:      newZipCode      ,
      city:         newCity         ,
      country:      newCountry      ,
      phoneNumber:  newPhoneNumber  ,
      website:      newWebsite      ,
      instagram:    newInstagram    ,
      facebook:     newFacebook     ,
      tiktok:       newTiktok       ,
      bio:          newBio          ,
      verify:       newVerify       ,
	  dateCreation: newDateCreation ,
	  photos:       []
	}
   }
   
  return ( dispatch: DispatchTypeAddRegistration ) =>
   {
    dispatch (action);
   }
 } 
 
export const addPhoto = ( numRegistration:        number  ,
                          newId:                  number  ,
                          newFile:                string  ,
                          newName:                string  ,
                          newDescription:         string  ,
                          newChoosen:             boolean ,
                          newAuthorCertification: boolean ,
                          newCameraBrand:         string  ,
						  newCameraModel:         string  ,
                          newNewState:            number  ,
						  newReview:              string  ,
						  newDateCreation:        string  ,
                          newDateReview:          string  ,
						  newNbVotes:             number  ) =>
 {
  const action: actionAddPhoto =
   {
    type:            ActionType.ADD_PHOTO,
	numRegistration: numRegistration,
	newPhoto: {
      id:                  newId                  ,
      file:                newFile                ,
      name:                newName                ,
      description:         newDescription         ,
      choosen:             newChoosen             ,
      authorCertification: newAuthorCertification ,
      cameraBrand:         newCameraBrand         ,
      cameraModel:         newCameraModel         ,
      newState:            newNewState            ,
	  review:              newReview              ,
	  dateCreation:        newDateCreation        ,
	  dateReview:          newDateReview          ,
	  nbVotes:             newNbVotes
	}
   }
   
  return ( dispatch: DispatchTypeAddPhoto ) =>
   {
    dispatch (action);
   }
 } 
 
export const updatePhotoReview = ( numRegistration: number ,
                                   numPhoto:        number ,
                                   newReview:       string ) => 
 {
  const action: actionUpdatePhotoReview =
   {
    type:            ActionType.UPDATE_PHOTO_REVIEW,
	numRegistration: numRegistration,
	numPhoto:        numPhoto,
	newReview:       newReview
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoReview ) => 
   {
    dispatch (action);
   }
 } 

export const initClassement = () => 
 {
  const action: actionInitClassement = 
   {
    type:          ActionType.INIT_CLASSEMENT,
   }

  return ( dispatch: DispatchTypeInitClassement ) => 
   {
    dispatch (action);
   }
 } 



export const addClassementPhoto = ( newId:           number  ,
                                    newFile:         string  ,
                                    newName:         string  ,
                                    newDescription:  string  ,
                                    newUrlThumbnail: string  ,
                                    newUrlMagnified: string  ,
                                    newFirstname:    string  ,
                                    newLastname:     string  ,
                                    newNbVotes:      number  ) =>
 {
  const action: actionAddClassementPhoto =
   {
    type:            ActionType.ADD_CLASSEMENT_PHOTO,
	newPhoto: {
       id:           newId  ,
       file:         newFile  ,
       name:         newName  ,
       description:  newDescription  ,
       urlThumbnail: newUrlThumbnail  ,
       urlMagnified: newUrlMagnified  ,
       firstname:    newFirstname  ,
       lastname:     newLastname  ,
       nbVotes:      newNbVotes
	}
   }
   
  return ( dispatch: DispatchTypeAddClassementPhoto ) =>
   {
    dispatch (action);
   }
 } 
 

import { Action, ActionType, Ensemble, Classement } from './actionTypes';


interface State 
 {
  ensemble:   Ensemble;
  classement: Classement;
 }

const initialState = 
 {
  ensemble: {
    registrations: [],
	typeInfos:     "tous"
    },	
  classement: {
    photos: []
    }	
 }

const RegistrationManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_ENSEMBLE:        state.ensemble.registrations = [];
										  state.ensemble.typeInfos     = action.newTypeInfos;
										 
                                          return state;

	case ActionType.ADD_REGISTRATION:     state.ensemble.registrations  = [...state.ensemble.registrations, action.newRegistration];

									      return state;

	case ActionType.ADD_PHOTO:            state.ensemble.registrations[action.numRegistration].photos      
	                                                      = [...state.ensemble.registrations[action.numRegistration].photos, action.newPhoto];

									      return state;

	case ActionType.UPDATE_PHOTO_REVIEW:  state.ensemble.registrations[action.numRegistration].photos[action.numPhoto].review = action.newReview;

									      return state;
										 
    case ActionType.INIT_CLASSEMENT:      state.classement.photos = [];
										 
                                          return state;

	case ActionType.ADD_CLASSEMENT_PHOTO: state.classement.photos = [...state.classement.photos, action.newPhoto];

                                          return state;

    default:                              return state;
   }
 }


export default RegistrationManagmentReducer;

import   React                               from 'react';
import   ReactDOM                            from 'react-dom/client';
import { createBrowserRouter              ,
         RouterProvider                   }  from 'react-router-dom';
import { Provider                         }  from 'react-redux';


import { Login                            }  from './components/page_login/login';


import { Account                          }  from './components/page_account/account';

import { Vote                             }  from './components/page_vote/vote';

import { NotMatch                         }  from './components/page_not_match/not_match';

import { store                            }  from './redux/store'; 

import './index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },

  {
    path: "/comptes",
    element: <Account />,
  },

  {
    path: "*",
    element: <NotMatch />,
  },
  

]);

const root = ReactDOM.createRoot ( document.getElementById('root') as HTMLElement );

root.render (

 <React.StrictMode>
   <Provider store={store}>
     <RouterProvider router={router} />
   </Provider>
 </React.StrictMode>
 
);

import   React      , 
       { useEffect  ,
         useState   }  from 'react';
import   classNames    from 'classnames';

import   styles        from './window_photo.module.scss';

export interface WindowPhotoProps {
  className?:           string;
  onClickOnCloseButton: (() => void)
  url:                  string
}

export const WindowPhoto = ({ className, onClickOnCloseButton, url }: WindowPhotoProps) => {

  const handleClick = () => {
       onClickOnCloseButton();
  };

/*
  return (

    <>

      <img src       = {url} 
           className = { classNames(styles.photo, className) } 
           onClick   = {handleClick}
           alt       = "zoom"                                 />
		
      <div className={classNames(styles.croix, className)}>
		<span onClick = {handleClick}>&#x2573;</span>
      </div>
		
	</>
    );
*/

  return (

    <div className = { classNames(styles.cadrePhoto, className) } >

      <img src       = {url} 
           className = { classNames(styles.photo, className) }  
           alt       = "zoom"                                 />
		
      <div className={classNames(styles.divCroix, className)}>
		<span className={classNames(styles.spanCroix, className)} onClick = {handleClick}>&#x2573;</span>
      </div>
		
	</div>
    );

};

export default Window;

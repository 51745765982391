import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames              from 'classnames';
import { Link                 }  from 'react-router-dom';

import   styles                  from './not_match.module.scss';


export interface NotMatchProps {
  className?: string;
}

export const NotMatch = ({ className }: NotMatchProps) => {

  return (
  
        <>


          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
	          <div className={classNames(styles.texte, className)}>
                <h1>La page que vous recherchez n'existe pas</h1>
              </div>
  
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>
            
  );
};

export default NotMatch;

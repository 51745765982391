import { combineReducers }            from 'redux';
import CookiesManagmentReducer        from './cookiesManagment/reducer';
import RegistrationManagmentReducer   from './registrationManagment/reducer';
import AuthenticationManagmentReducer from './authenticationManagment/reducer';

const reducers = combineReducers({
    cookiesManagment:        CookiesManagmentReducer        ,
    registrationManagment:   RegistrationManagmentReducer   ,
    authenticationManagment: AuthenticationManagmentReducer
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;
import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames          from 'classnames';
import { Dispatch         }  from 'redux';
import { useDispatch      }  from 'react-redux';
import   axios            , 
       { AxiosError       }  from 'axios';
import { jwtDecode        }  from "jwt-decode";                // Pas utilisé ici
import { Link             ,
         useNavigate      ,
         redirect         ,
         useLocation      ,
         useSearchParams  }  from 'react-router-dom';
import { format           ,
         parseISO         }  from 'date-fns';

import   styles              from './login.module.scss';

import { initAuthentication,
         setLogin          , 
         setToken          ,
		 setState          }  from '../../redux/authenticationManagment/actionCreators';

import { useTypedSelector }  from '../../hooks/useTypeSelector';
import { store            }  from '../../redux/store';

import { Field            }  from '../widget_field/field'; 
import { Trigger          }  from '../widget_trigger/trigger';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

export interface LoginProps {
  className?: string;
}

export const Login = ({ className }: LoginProps) => {
  
  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();
    
  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
    
  const [ email                 , setEmail             ] = useState('');
  const [ password              , setPassword          ] = useState('');
  const [ showMessageError      , setShowMessageError  ] = useState(0);

  useEffect(() => {

    if ( authentication.login != "" && 
         authentication.token != "" )
     {
      navigate ("/comptes");
     }
  }); 
  
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {

    try {

      console.log (`${API_URLS.PostAuth}`)

      const response = await axios.post (

                                                   `${API_URLS.PostAuth}`,

                                   //        'https://api.lovelightfactory.com/auth',

                                          { email    ,
                                            password }
                                         );

	  console.log (response)
                                          
      if ( response.status == 200 )
            {
             dispatch ( setLogin (email) );
             dispatch ( setToken (response.data.token) );
             dispatch ( setState (1) );

             logConnection ( email , "succes" );

             navigate("/comptes");
            }
       else {
             logConnection ( email , "autre" );
                  
             setShowMessageError ( 1 )
               }
    } 
    catch (error) {
    
		  console.log (error)

      if (axios.isAxiosError(error) && error.response)
            {
             logConnection ( email ,  error.response.data.message );
            }
       else {
             logConnection ( email , "erreur de connexion" );
            }
    
      setShowMessageError ( 1 )

    }
  };

  const logConnection = async ( login:string , status:string ) => {
  
      const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json'
    };
  
        try 
         {
          const response = await axios.post (
  //                                             'https://api.lovelightfactory.com/api/connections',

                                               `${API_URLS.PostConnection}` ,

                                              { login  ,
                                                status },
                                            
                                             {
                                               headers: headers
                                             }
                                            );


         } 
        catch (error) {
           setShowMessageError ( 1 )

        }
  
  }

  return (

        <>

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
              
              <img src="/images/LovelightChallenge_1024x1024.png" 
                   className={classNames(styles.vignette, className)} /> 

   
              <br/>
    
              <h1 className={classNames(styles.titre, className)}>Authentification</h1>

              <Field name         = "Adresse E-mail"
                     type         = "email"
                     placeholder  = "Entrez votre adresse e-mail"
                     value        = {email}
                     error        = ""
                     onChange     = {handleEmailChange}
                     autocomplete = "on"                          />
               
               
                <Field   name         = "Mot de passe"
                         type         = "password"
                         placeholder  = "Entrez votre mot de passe"
                         value        = {password}
                         error        = ""
                         onChange     = {handlePasswordChange}
                         autocomplete = "on"                        />
				

               
              <Trigger name        = "Se connecter"
                       link        = "#"
                       onClick     = {handleLogin}
                       className   = {classNames(styles.trigger_login, className)}
                       touch       = "Enter" />

              <br/>
                             
              { 
                  ( showMessageError == 1 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_error, className)}>
                      Erreur d'authentification.
                     </p>
                    </div> )  
              }

              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>

           
         );


};

export default Login;

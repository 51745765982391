import   React                ,  
       { useEffect            , 
         useState             }  from "react";
import { Link                 ,
         useNavigate          }  from 'react-router-dom';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import   Toggle                  from "react-native-toggle-element";   //  https://github.com/mymai91/react-native-toggle-element
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                , 
       { AxiosError           }  from 'axios';
import { Container            , 
         Typography           , 
         Grid                 , 
         Switch               }  from '@mui/material';

import   styles                  from './account.module.scss';

import { initEnsemble         ,
		 addRegistration      ,      
		 addPhoto             }  from '../../redux/registrationManagment/actionCreators';

import { initAuthentication   ,
         setLogin             , 
         setToken             ,
		 setState             }  from '../../redux/authenticationManagment/actionCreators';

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';

import { Field                }  from '../widget_field/field';
import { Select               }  from '../widget_select/select';
import { TextArea             }  from '../widget_textarea/textarea';
import { Trigger              }  from '../widget_trigger/trigger';

import { Album                }  from '../bloc_album/album';
import { Profil               }  from '../bloc_profil/profil';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

interface Photo {
  id:                  number ,
  file:                string ,
  name:                string ,
  description:         string ,
  cameraBrand:         string ,
  cameraModel:         string ,
  choosen:             string ,
  authorCertification: string ,
  reviewState:         string ,
  dateCreation:        string ,
  dateReview:          string  
}

export interface User
 {
  id:           number  ,
  email:        string  ,
  password:     string  ,
  password2:    string  ,
  firstname:    string  ,
  lastname:     string  ,
  sex:          string  ,
  birthday:     string  ,
  address:      string  ,
  address2:     string  ,
  zipCode:      string  ,
  city:         string  ,
  country:      string  ,
  phoneNumber:  string  ,
  website:      string  ,
  instagram:    string  ,
  facebook:     string  ,
  tiktok:       string  ,
  bio:          string  ,
  verify:       boolean ,
  dateCreation: string
 }

export interface AccountProps {
  className?: string;
}

const useBeforeRender = (callback: () => void, deps: any[]) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

export const Account = ({ className }: AccountProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { ensemble       } = useTypedSelector ((state) => state.registrationManagment   );

  const [ email                 , setEmail              ] = useState('');
  const [ firstname             , setFirstname          ] = useState('');
  const [ lastname              , setLastname           ] = useState('');


  const [ showMessageError      , setShowMessageError   ] = useState(false);
  const [ showMessageAck        , setShowMessageAck     ] = useState(false);
  
  const [ firstRender           , setFirstRender        ] = useState(true);

  const [ render                , setRender             ] = useState(0);
  const [ key                   , setKey                ] = useState(0);

  const [ stateAccount          , setStateAccount       ] = useState(authentication.state);

  const [ nbAccounts            , setNbAccounts         ] = useState(0);
  const [ nbPhotos              , setNbPhotos           ] = useState(0);

  const [ typeInfos              , setTypeInfos         ] = useState ("acceptée") //(ensemble.typeInfos);

  useEffect(() => {

    if ( authentication.login == "" || 
         authentication.token == "" || 
		 authentication.state == 0  )
          {
           navigate ("/");
          }
	 else {
           if ( firstRender )
            {
             if ( stateAccount !== 2 )
			  {
  			   getInfos ();
              }
			  
             setFirstRender (false);
            }

		  }
		  

  }, [typeInfos]); 

  const getInfos = async () => {
  
    const url = `${API_URLS.GetInfos2}?type=${typeInfos}`;

    const headers = {
          'Authorization': `Bearer ${authentication.token}`,
    };

    try {
	
      console.log (url)
	
      const response = await axios.get(url, { headers: headers });
      const statut = response.status;

      console.log (response)

      if ( statut == 200 )
       {
		const users = response.data['hydra:member'];

		users.map((user:User, numRegistration: number) => {
			    
			dispatch ( 
				addRegistration (   
					    user.id          ,
					    user.email       ,
					    user.password    ,
					    user.password2   ,
					    user.firstname   ,
					    user.lastname    ,
					    user.sex         ,
					    user.birthday    ,
					    user.address     ,
					    user.address2    ,
					    user.zipCode     ,
					    user.city        ,
					    user.country     ,
					    user.phoneNumber ,
					    user.website     ,
					    user.instagram   ,
					    user.facebook    ,
					    user.tiktok      ,
					    user.bio         ,
					    user.verify      ,
						user.dateCreation
				) 
			);
			
	        setNbAccounts((prevKey) => prevKey + 1);

			
			getPhotos ( user.email, numRegistration)
		})
       }
	   
	       setRender((prevKey) => prevKey + 1);

    } 
    catch (error) {

      setShowMessageAck   ( false );     
      setShowMessageError ( true );     
    }
	
  };
  
  const getPhotos = async (email: string,
                           numRegistration: number) => {
						   
			const baseUrl  = `${API_URLS.GetPhotosByEmail}?`; 
            const nomInfo1 = "email="; 
            const valInfo1 = encodeURIComponent (email);      

			const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    const headers = {
          'Authorization': `Bearer ${authentication.token}`,
    };

			try {
			
      console.log (url)

				const response = await axios.get(url, { headers: headers });
				const statut = response.status;

      console.log (response)

				if ( statut == 200 )  {

					const userPhotos = response.data['hydra:member'];

					userPhotos.map((photo:Photo) => {
			    
				/*
				        if ( 
						     ( ( typeInfos == "tous"      )                                         ) ||
						     ( ( typeInfos == "acceptée"  ) && ( photo.reviewState == "Acceptée"  ) ) ||
						     ( ( typeInfos == "refusée"   ) && ( photo.reviewState == "Refusée"   ) ) ||
						     ( ( typeInfos != "acceptée" && 
							     typeInfos != "refusée"   ) && ( photo.reviewState == "Non revue" ) )
						   )
				*/
				        if ( ( typeInfos == "acceptée"  ) && ( photo.reviewState == "Acceptée"  ) ) 
						   
				        {
						dispatch ( 
							addPhoto (
                                numRegistration                       ,							
								photo.id                              ,
								photo.file                            ,
								photo.name                            ,
								photo.description                     ,
							  ( photo.choosen             == "true" ) ,
							  ( photo.authorCertification == "true" ) ,
								photo.cameraBrand                     ,
								photo.cameraModel                     ,
								2                                     ,
								photo.reviewState                     ,
								photo.dateCreation                    ,
								( photo.dateReview != null ? photo.dateReview : "" ),
								0
							) 
						);
						
						setNbPhotos((prevKey) => prevKey + 1);
                        }
					})
				}
				
    setRender((prevKey) => prevKey + 1);
			} 
			catch (error) {
			}
  
  };
  
  const handleLogoutClick = () => {

    dispatch ( initAuthentication () );
    dispatch ( initEnsemble       ("tous") );
        
    navigate("/");
  };
  
  const handleTypeInfosChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    console.log (e.target.value);

    setTypeInfos (e.target.value); 

	console.log ( typeInfos );
    
	dispatch ( initEnsemble (e.target.value) ) ;
	
	console.log ( ensemble );
	
	setNbAccounts (0);
    setNbPhotos   (0);

  };

  
  return (

          
          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <p>{nbPhotos} photos</p>

              <br/>

              <div className={classNames(styles.espace_haut, className)}>
              </div>

              {
			    ( 
				  render == 0 
				)
			    ?
				(
				  <></>
				)
				:
				(
	               ensemble.registrations.map ( ( user, index ) => (
	          
			         <>
					   <Album  user={user} />
					 </>
					 
                     ) )
	            )
	          }
	          
			  
			  
            </div>
			
          </div>
        
            
  );
};

export default Account;

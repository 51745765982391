import   React             , 
       { useEffect         , 
         useState          }  from 'react';
import { Dispatch          }  from 'redux';
import { useDispatch       }  from 'react-redux';

import   axios                from 'axios';

import   classNames           from 'classnames';



import   styles               from './photo.module.scss';

import { Action            }  from '../../redux/registrationManagment/actionTypes';
         
import { updatePhotoReview }  from '../../redux/registrationManagment/actionCreators';
import { Registration }  from '../../redux/registrationManagment/actionTypes';

import { useTypedSelector  }  from '../../hooks/useTypeSelector';
import { store             }  from '../../redux/store';

import { Field             }  from '../widget_field/field';
import { Select            }  from '../widget_select/select';
import { Toggle            }  from '../widget_toggle/toggle';
import { TextArea          }  from '../widget_textarea/textarea';
import { Trigger           }  from '../widget_trigger/trigger';
import { WindowPhoto       }  from '../window_photo/window_photo';
import { WindowQuestion    }  from '../window_question/window_question';
import { WindowMessage     }  from '../window_message/window_message';

import { API_URLS          ,
         WWW_URLS          }  from '../../urls/urls';

/*****************/
/* Le bloc photo */
/*****************/
 
export interface PhotoProps {
  className?:        string;
  index:            number; 
  user:       Registration;
}
 
export const Photo = ({ className , 
                        index     ,
						user      }: PhotoProps) => {
                        
  const dispatch: Dispatch<any> = useDispatch()

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { ensemble       } = useTypedSelector ((state) => state.registrationManagment   );                        

  const [ messageName                , setMessageName                ] = useState('');
  const [ messageAuthorCertification , setMessageAuthorCertification ] = useState('');
  
  const [ imageUrl                   , setImageUrl                   ] = useState ( "" );
  const [ imageUrlZoom               , setImageUrlZoom               ] = useState ( "" );
  
  const [ showZoom                   , setShowZoom                   ] = useState ( false );
  const [ showError                  , setShowError                  ] = useState ( false );
  const [ errorMessage               , setErrorMessage               ] = useState ( "" );


  const [ firstRender                , setFirstRender                ] = useState ( true );

  const [ updateButtonClass          , setUpdateButtonClass          ] = useState ( "styles.little_trigger" );
  
  const [ review                     , setReview                     ] = useState ( "Acceptée" );

  const [ messageClick               , setMessageClick                     ] = useState ( "" );
  const [ nbEffectues                , setNbEffectues                     ] = useState ( 0 );

  useEffect(() => {

      getInfos ();    
      
  }, [] ); 

  const getInfos = () => {

           const fileName   = user.photos[index].file;   

           const serveur    = new String ( "https://photos.lovelightchallenge.com/" );   
           const extension1 = new String ( "_mini.jpeg" );   
           const extension2 = new String ( ".jpeg" );   
    
           setImageUrl     ( serveur.concat(fileName.toString()).concat(extension1.toString()) );
           setImageUrlZoom ( serveur.concat(fileName.toString()).concat(extension2.toString()) );
	  
	setFirstRender ( false);	  
  }
  

  const handlePhotoZoomClick = () => {
   
    setShowZoom( true );
  }

  const handlePhotoInformationUpdateClick = async () => {
   
      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${authentication.token}`,
      };

      const baseUrl     = `${API_URLS.PatchPhoto}`; 

      const url = `${baseUrl}${user.photos[index].id}`;
	  
	  console.log (url);
  
      try {

        const response = await axios.patch (
        
                                            url,
                              
                                               { 
                                                 review 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );
											
        console.log (response);
		
		setNbEffectues((prevKey) => prevKey + 1);

        setMessageClick ( (nbEffectues <= 1 ? "effectué" : `effectué ${nbEffectues} fois` ));
		
      } 
      catch (error) {
        console.log (error);

      }
    

  }

  const handleClosePhotoZoomClick = () => {
   
    setShowZoom( false );
  }

  const handleReviewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setReview (e.target.value); 
  };

  return (
  
    <div className={classNames(styles.root, className)}>
    
      { 
        showZoom && 
        ( <WindowPhoto onClickOnCloseButton = {handleClosePhotoZoomClick} url = {imageUrlZoom} /> ) 
      }

      <div className={classNames(styles.photo_nom, className)} >Photo n°{user.photos[index].id}</div>

      <div className={classNames(styles.bloc, className)}>

        <div className={classNames(styles.bloc_ligne_photo, className)}>
          
          <img src={imageUrl} 
               className={classNames(styles.vignette, className)} 
               onClick= {handlePhotoZoomClick}/>    
          
        </div>
     
        <div className={classNames(styles.bloc_ligne_info, className)}>

          <div className={classNames(styles.grid, className)}>
                
            <div className={classNames(styles.name_nom, className)}>
              Nom de la photo
            </div> 

            <div className={classNames(styles.name_data, className)}>
               {user.photos[index].name}
            </div> 

            <div className={classNames(styles.dateCreation_nom, className)}>
              Date de création
            </div> 

            <div className={classNames(styles.dateCreation_data, className)}>
               {  ( new Date( user.photos[index].dateCreation ) ). toLocaleString() }
            </div> 

            <div className={classNames(styles.cameraBrand_nom, className)}>
               Marque de l'appareil photo
            </div> 

            <div className={classNames(styles.cameraBrand_data, className)}>
               {user.photos[index].cameraBrand}
            </div>
                   
            <div className={classNames(styles.cameraModel_nom, className)}>
               Modèle de l'appareil photo
            </div>
                   
            <div className={classNames(styles.cameraModel_data, className)}>
               {user.photos[index].cameraModel}
            </div>
                   
            <div className={classNames(styles.description_nom, className)}>
               Description
            </div>

            <div className={classNames(styles.description_data, className)}>
               {user.photos[index].description}
            </div>


          </div>

        </div>


           {/*}
        <div className={classNames(styles.bloc_ligne_boutons, className)}>

           <Select  name        = ""
                    choices    = { [
                                     "Acceptée",
                                     "Refusée"
                                   ] }
                    value       = {review}
                    onChange    = {handleReviewChange}     />
					   
					   
           <Trigger link      = "#"
                    name      = "Modifier" 
					onClick   = {handlePhotoInformationUpdateClick}
                    className = {classNames(styles.little_trigger, className)} /> 
					
			{messageClick}
                        
        </div>
            */}
      </div>
	
    </div>
  );
};

export default Photo;
